.Contact-content {
  display: grid;
  grid-template: 1fr / 16rem 1fr;
  grid-gap: 3rem;
  margin-bottom: 3rem;
}

.Contact-text {
  align-self: center;
}

@media screen and (max-width: 40rem) {
  .Contact {
    height: 50rem;
    padding: 25rem 0 6rem 0;
    text-align: center;
    color: white;
    background-image: url('AstronautAndPlanet.svg');
    background-size: cover;
    background-position: top;
  }

  .Contact-content {
    display: grid;
    grid-template: 1fr / auto;
    justify-items: center;
    align-items: center;
  }

  .Contact-image {
    display: none;
  }
}
