.Content {
  display: grid;
  align-items: center;
  text-align: center;
}

.Buttons {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  margin-top: var(--spacing-medium);
}

.SecondaryButton {
  margin-left: var(--spacing-small);
}

@media screen and (max-width: 40rem) {
  .Buttons {
    flex-direction: column;
  }

  .SecondaryButton {
    margin-top: var(--spacing-small);
    margin-left: 0;
  }
}
