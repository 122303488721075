.tile {
  width: 20rem;
  border: 2px solid var(--color-black-8);
  border-radius: 0.5rem;
  cursor: pointer;
  box-shadow: var(--shadow-default);
  transition: background-color 0.2s ease-out;
  align-items: center;
}

.anchor {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.75rem 1rem;
  align-content: center;
  justify-content: center;
  gap: var(--spacing-small);
  color: var(--color-black);
}

.newBadge {
  width: 3rem;
  position: absolute;
  top: 1rem;
  background-color: var(--color-sea-green);
}

.tile:hover {
  background-color: var(--color-mint-green-12);
}

.tile:active {
  border: 2px solid var(--color-ocean-green);
}

@media screen and (max-width: 50rem) {
  .tile {
    width: 20rem;
    margin: auto;
  }
}
