.Container {
  display: flex;
  flex-direction: column;
}
.CardRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.CodeBlock {
  width: 100%;
}
