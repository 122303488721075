.Container {
  width: 90%;
  max-width: 60rem;
}

.Container--size-medium {
  max-width: 50rem;
}

.Container--size-small {
  max-width: 40rem;
}
.Container--size-x-large {
  max-width: 80rem;
}

.Container--centered {
  margin-left: auto;
  margin-right: auto;
}
