.ActionsColumn {
  white-space: nowrap;
}

.TableWrapper {
  overflow-x: auto;
  position: relative;
}

.Bar {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: flex-start;
}

.NumberOfProviders {
  font-weight: var(--font-weight-medium);
}
