.ApiCard {
  padding-bottom: var(
    --spacing-small
  ) !important; /* override eufemia-card padding */
}
.TopSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.Version {
  color: var(--color-black-55);
}

.Divider {
  color: var(--color-black-8);
  padding-bottom: var(--spacing-small);
}

.ButtonsContainer {
  display: flex;
  justify-content: space-between;
}

.ClassificationTag {
  text-transform: capitalize;
}
