.AccordionTitle {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;
}

.AccordionTitle--Status {
  color: var(--color-black-55);
  font-weight: 400;
}

.AccordionTitleIcon {
  border-radius: var(--spacing-small);
  padding: 4px 6px;
  margin-left: var(--spacing-small);
}

.AccordionTitleIcon-development {
  background-color: var(--color-black-20);
  color: black;
}

.AccordionTitleIcon-production {
  background-color: var(--color-success-green);
  color: white;
}

.ContentContainer-Buttons {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}
