.toast {
  position: fixed;
  max-width: 600px;
  min-width: 450px;
  width: fit-content;
  display: flex;
  align-items: center;
  padding: var(--spacing-small);
  border-radius: 8px;
  z-index: 9999;
}

.toast-top {
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.toast-top-left {
  top: 20px;
  left: 20px;
}

.toast-top-right {
  top: 20px;
  right: 20px;
}

.toast-bottom {
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.toast-bottom-left {
  bottom: 20px;
  left: 20px;
}

.toast-bottom-right {
  bottom: 20px;
  right: 20px;
}

.toast-success {
  background-color: var(--color-success-green);
  color: var(--color-white);
}

.toast-warn {
  background-color: var(--color-accent-yellow);
  color: var(--color-black-80);
}

.toast-error {
  background-color: var(--color-fire-red);
  color: var(--color-white);
}

.message {
  font-weight: var(--font-weight-medium);
}
