.dnb-flex-container--direction-horizontal {
  margin-right: 0;
}

.HeroContent {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100%;
  align-content: center;
}

.heroTextContainer {
  width: 80rem;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.HeroText {
  justify-content: center;
  max-width: 50vw;
  color: var(--color-white);
}

@media screen and (max-width: 70rem) {
  .HeroText {
    justify-content: center;
    justify-items: center;
    text-align: center;
    max-width: 80vw;
    color: var(--color-white);
  }
}

@media screen and (max-width: 50rem) {
  .HeroText {
    justify-content: center;
    justify-items: center;
    text-align: center;
    max-width: 80vw;
    color: var(--color-white);
  }
}
