.badge {
  padding: 0.25rem 0.5rem;
  display: inline-block;
  border-radius: 0.5rem;
  color: white;
}

.rest {
  background-color: var(--color-summer-green);
}

.event {
  background-color: var(--color-sky-blue);
}
