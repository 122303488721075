.InformationBoxContainer {
  padding: 1rem 0 9rem;
}

.InformationContent {
  padding: 1.5rem 1rem;
  height: 100%;
  width: 70%;
}

.InformationSection {
  max-width: 752px;
  padding: 0.5rem 3rem 0.5rem 1rem;
  border-radius: 8px 0px 0px 0px;
  background-color: var(--color-pistachio);
  border-radius: 0.5rem;
}

@media screen and (max-width: 50rem) {
  .InformationBoxContainer {
    padding: 0 0 9rem;
    margin: 3rem;
  }

  .InformationSection {
    padding: 1rem;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap-reverse;
  }

  .InformationContent {
    padding: 0;
    width: 100%;
  }
}
