.MenuLinks {
  display: flex;
  justify-content: baseline;
  align-items: baseline;
  gap: 1rem;
}

.hamburgerDialog {
  background-color: var(--color-white);
  height: 100%;
  width: 100%;
  position: fixed;
  justify-content: flex-start;
  border-radius: 0;
  left: 0;
  z-index: 100;
  padding: 1rem;
  max-height: 100%;
  transition: all 0.2s;
  overflow-y: scroll;
  padding-bottom: 10rem;
}

@media screen and (max-width: 73rem) {
  .MenuLinks {
    gap: 0;
  }
}
