.Container {
  display: flex;
  justify-content: space-between;
}

.Container-Scope {
  display: flex;
  gap: var(--spacing-small);
  align-items: baseline;
  justify-content: center;
}

.Icon {
  align-self: center;
  padding: 2px 6px;
  border-radius: 4px;
}

.Icon--pending {
  background-color: var(--color-lavender);
}

.Icon--approved {
  background-color: var(--color-pistachio);
  color: var(--color-success-green);
}

.Icon--denied {
  background-color: var(--color-fire-red-8);
  color: var(--color-fire-red);
}
