.ScrollMenu {
  font-size: var(--font-size-medium);
  top: 2rem;
}

.ScrollMenu ul {
  margin: 0;
  padding: 0;
  display: grid;
  gap: 1.5rem;
}

.ScrollMenu a {
  color: var(--color-black-55);
  text-decoration: none;
}

.ScrollMenu a:hover {
  color: var(--color-black-80);
}

.ScrollMenu-item.ScrollMenu-item--active {
  color: var(--color-black-80);
  font-size: var(--font-size-large);
}
