.StatusWidget {
  background-color: var(--color-white);
  border-radius: 0.5rem;
  padding: 2rem;
  display: grid;
  grid-template-columns: 10rem auto;
  align-items: center;
  gap: 2rem;
  max-width: 40rem;
}

.StatusWidget--severity-0 .StatusWidget-header {
  color: var(--color-success-green);
}

.StatusWidget--severity-1 .StatusWidget-header {
  color: var(--color-accent-yellow);
}

.StatusWidget--severity-2 .StatusWidget-header {
  color: var(--color-fire-red);
}

@media screen and (max-width: 60rem) {
  .StatusWidget {
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    text-align: center;
  }
}
