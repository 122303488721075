.FooterContent {
  width: 90vw;
  max-width: 960px;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  color: var(--color-white);
}

.FooterList {
  list-style: none;
  margin: 0;
  padding: 0;
}

.Logo {
  align-self: center;
  color: var(--color-white);
}

@media screen and (max-width: 60rem) {
  .FooterContent {
    flex-direction: column;
    text-align: center;
  }
}
