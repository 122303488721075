.type {
  color: var(--color-black-55);
}

.discriminator {
  color: var(--color-violet);
}

.deprecated {
  background-color: var(--color-accent-yellow);
  padding: 0.125rem 0.25rem;
  display: inline-block;
  border-radius: 2px;
  color: black !important;
  margin-top: var(--spacing-x-small);
}

.enum-list {
  display: flex;
  flex-wrap: wrap;
  column-gap: var(--spacing-x-small);
  row-gap: var(--spacing-small);
}
