.PublishApiSection {
  background: var(--color-pistachio);
  border-radius: var(--spacing-small);
  padding: var(--spacing-medium);
}

.Accordion > section {
  padding: var(--spacing-small) var(--spacing-x-small) !important;
}

.Card {
  border: solid 4px var(--color-black-8);
}
