.Container {
  min-height: 49vh;
}

.Actions {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: var(--spacing-medium);
}

.Members {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--spacing-small);
}

.MemberTile {
  row-gap: var(--spacing-small);
}

.MemberBoxDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing-medium);
}

.MemberDetails {
  display: flex;
  align-items: center;
}

.DateDetails {
  text-align: right;
}

.EmailAndDate {
  color: var(--color-black-55);
  font-size: 14px;
}

.Badge {
  padding: calc('xx-large', 'x-small');
  background-color: var(--color-pistachio);
  color: var(--color-emerald-green);
}

@media screen and (max-width: 50rem) {
  .Members {
    grid-template-columns: 1fr;
  }

  .Avatar {
    display: none;
  }
}
