.union-container {
  background-color: var(--color-white);
  padding: var(--spacing-small);
  border: 1px solid var(--color-black-8);
}

.union-type {
  color: var(--color-black-55);
  font-size: var(--font-size-basis);
  margin: 0;
}
