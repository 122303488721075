.Credential-Container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-small);
}

.Credential-Card {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: var(--spacing-medium);
}

@media screen and (max-width: 50rem) {
  .Grid {
    grid-template-columns: 1fr;
  }
}
