.ServicePageContent {
  max-width: 80rem;
  padding: 0 1rem 8rem;
  margin: auto;
}

.TileContainer {
  display: flex;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
}
