.Filters {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: var(--spacing-small);
  align-items: start;
}

.dnb-dropdown {
  --dropdown-width: 13rem;
}
