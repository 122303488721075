.Card {
  padding-bottom: var(
    --spacing-small
  ) !important; /* override eufemia-card padding */
}

.Divider {
  color: var(--color-black-8);
  padding-bottom: var(--spacing-small);
}
