:root {
  --api-status: var(--color-summer-green);
}
.apiStatusWidget {
  background-color: var(--color-white);
  border-radius: 0.5rem;
  padding: 1rem;
  align-items: center;
  width: fit-content;
}

.apiStatusBadge {
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 0.75rem;
  background-color: var(--api-status);
  position: relative;
}

.apiStatusBadge-severity-0 {
  --api-status: var(--color-summer-green);
}

.apiStatusBadge-severity-1 {
  --api-status: var(--color-accent-yellow);
}

.apiStatusBadge-severity-2 {
  --api-status: var(--color-fire-red);
}

.apiStatusBadge:after {
  content: '';
  position: absolute;
  top: 0.15rem;
  left: 0.125rem;
  width: 0.5rem;
  height: 0.5rem;
  display: inline-block;
  border-radius: 0.75rem;
  box-shadow: 0 0 0 0.125rem var(--api-status);
  animation: dot-animation 1s infinite;
}

@keyframes dot-animation {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(2);
    opacity: 0;
  }
}
