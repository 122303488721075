.Status-operational {
  color: var(--color-success-green);
}

.StatusCard-button {
  color: var(--color-black-80);
  text-align: left;
  font-size: var(--font-size-basis);
  background-color: var(--color-white);
  padding: 1.5rem 1rem;
  border: 1px solid var(--color-black-8);
  border-radius: 0.25rem;
  display: grid;
  grid-template-columns: min-content 1fr auto;
  gap: 1rem;
  align-items: center;
  width: 100%;
}

.StatusCard--expanded .StatusCard-button {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-width: 0;
}

.StatusCard-button svg {
  transform: none;
  transition: transform 0.2s;
}

.StatusCard--expanded .StatusCard-button svg {
  transform: rotate(180deg);
}

.StatusCard--severity-1 .StatusCard-button,
.StatusCard--severity-1 .StatusCard-content {
  border-color: var(--color-accent-yellow);
}

.StatusCard--severity-2 .StatusCard-button,
.StatusCard--severity-2 .StatusCard-content {
  border-color: var(--color-fire-red);
}

.StatusCard--severity-0 .StatusCard-button:hover {
  background-color: var(--color-mint-green-25);
}

.StatusCard--severity-1 .StatusCard-button:hover {
  background-color: var(--color-sand-yellow);
}

.StatusCard--severity-2 .StatusCard-button:hover {
  background-color: var(--color-fire-red-8);
}

.StatusCard-severity {
  font-weight: var(--font-weight-medium);
}

.StatusCard--severity-0 .StatusCard-severity {
  color: var(--color-success-green);
}

.StatusCard--severity-1 .StatusCard-severity {
  color: var(--color-accent-yellow);
}

.StatusCard--severity-2 .StatusCard-severity {
  color: var(--color-fire-red);
}

.StatusCard-content {
  background-color: var(--color-white);
  padding: 1px 3.5rem 3.5rem 3.5rem;
  border: 1px solid var(--color-black-8);
  border-top-width: 0;
  border-radius: 0 0 0.25rem 0.25rem;
  display: none;
}

.StatusCard--expanded .StatusCard-content {
  display: block;
}

.StatusCard-ongoing {
  position: relative;
  color: var(--color-fire-red);
}

.StatusCard--severity-1 .StatusCard-ongoing {
  color: var(--color-accent-yellow);
}

.IncidentCard {
  background-color: var(--color-white);
  border: 1px solid var(--color-black-8);
  border-radius: 0.25rem;
  padding: 1rem;
}

.IncidentCard-resolved {
  display: inline-block;
  background-color: var(--color-lavender);
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
}

/* Dot */

.StatusCard-ongoing:before {
  content: '';
  width: 0.75rem;
  height: 0.75rem;
  display: inline-block;
  background: var(--color-fire-red);
  border-radius: 0.375rem;
  margin-right: 1rem;
}

.StatusCard-ongoing:after {
  content: '';
  position: absolute;
  top: 0.7rem;
  left: 0.125rem;
  width: 0.5rem;
  height: 0.5rem;
  display: inline-block;
  box-shadow: 0 0 0 0.125rem var(--color-fire-red);
  border-radius: 0.25rem;
  animation: dot-animation 1s infinite;
}

.StatusCard--severity-1 .StatusCard-ongoing:before {
  background: var(--color-accent-yellow);
}

.StatusCard--severity-1 .StatusCard-ongoing:after {
  box-shadow: 0 0 0 0.125rem var(--color-accent-yellow);
}

@keyframes dot-animation {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(2.5);
    opacity: 0;
  }
}

/**
 * Mobile styles
 */

@media screen and (max-width: 60rem) {
  .StatusCard-content {
    padding: 1px 1rem 1rem 1rem;
  }
}
