.buttonContainer {
  margin: 0.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  & .dnb-space__right--small {
    margin-right: 0;
  }
}

.navigationButton {
  border-radius: 0.5rem;
  line-height: var(--line-height-large);
  border: 0;
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;
  align-items: center;
  background-color: var(--color-white);
  transition: all 0.2s;
  color: var(--color-emerald-green);
  cursor: pointer;
}

.navigationButton:hover {
  background-color: var(--color-local-interaction-green);
}

.navigationButtonActive {
  background-color: var(--color-local-interaction-green);
}

.navigationButtonText {
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-bold);
}

.chevron {
  transition: all 0.2s;
}

.chevronRotate {
  transform: rotate(180deg);
}

.navigationIcon {
  visibility: visible;
}

.dropdownLink {
  width: 100%;
  padding: 1rem 1rem;
  min-height: 4.5rem;
  margin: 0;
}

.Avatar {
  border: 0;
  height: var(--line-height-x-large);
  background-color: var(--color-white);
  transition: all 0.2s;
  color: var(--color-emerald-green);
  cursor: pointer;
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 0.5rem;
  gap: 0.2rem;
}

.Avatar:hover {
  background-color: var(--color-local-interaction-green);
}

.AvatarButtonText {
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-regular);
}

.avatarChevron {
  margin: 0 0 0 0.1rem;
}

.dropdownLinkProfile {
  width: 100%;
  padding: 1rem 1rem;
  min-height: 4.5rem;
  margin: 0;
}

.dropdownLinkProfile:hover {
  background-color: var(--color-local-interaction-green);
  color: var(--color-emerald-green);
}

.dropdownLink:hover {
  background-color: var(--color-local-interaction-green);
  color: var(--color-emerald-green);
}

.dropdownTextWrapper {
  max-width: 100%;
}

.dropdownTitle {
  font-size: var(--font-size-basis);
  stroke-width: 1.5;
  font-weight: var(--font-weight-regular);
}

.dropdownSubtitle {
  color: var(--color-black-55);
  max-width: 15rem;
}

.profileMenuWrapper {
  display: flex;
  align-content: center;
  justify-content: flex-end;
  height: 4rem;
  cursor: pointer;
  color: var(--color-sea-green);
}

.profileDropdown {
  background-color: var(--color-white);
  position: absolute;
  top: 2.5rem;
  z-index: 100;
  width: 20rem;
  border-radius: 0.5rem;
  transition: all 0.2s ease-out;
  box-shadow: var(--shadow-default);
}

.profileDropdownVisible {
  opacity: 1;
  top: 4.25rem;
  visibility: visible;
}

.profileDropdownHidden {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.profileDropdownItemDivider {
  padding: 0 0.5rem;
}

.arrowUp {
  position: inherit;
  right: 0.5rem;
  top: -0.5rem;
  width: 0rem;
  height: 0rem;
  border-left: 0.5rem solid transparent;
  border-right: 0.5rem solid transparent;
  border-bottom: 0.5rem solid var(--color-white);
  font-size: 0;
  line-height: 0;
}

.avatarWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  transition: all 0.2s;
}

.profileAvatarWrapper {
  --dropdown-height: none;
}

@media screen and (max-width: 77rem) {
  .navigationIcon {
    visibility: hidden;
    display: none;
    width: 0;
    opacity: 0;
  }

  .navigationButton {
    gap: 0;
  }

  .usernameAndChevronWrapper {
    max-width: 7rem;
  }

  .profileDropdownItemDivider {
    margin: 1rem;
  }
}

@media screen and (max-width: 60em) and (min-width: 0) {
  .buttonContainer {
    margin: 0.5rem 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  li.buttonContainer {
    margin: 1rem 0.5rem;
  }

  .navigationButton {
    gap: 1rem;
    width: 100%;
    padding: 1.5rem;
    justify-content: space-between;
  }

  .navigationIcon {
    visibility: visible;
    display: block;
    width: auto;
    opacity: 1;
  }

  .navigationButtonText {
    line-height: 1.5rem;
    font-size: 1.25rem;
  }

  .chevron {
    margin-left: auto;
    font-size: 1rem;
  }

  .dropdownContainer {
    background-color: var(--color-white);
    position: relative;
    top: 0;
    height: 0;
    padding: 0;
    margin: 0;
    z-index: 100;
    width: 100%;
    transition: all 0s;
    box-shadow: none;
  }

  .dropdownLinkProfile {
    padding: 1rem 0;
    min-height: 0;
  }

  .dropdownLinkProfile {
    padding: 0;
    min-height: 0;
  }

  .dropdownLinkProfile:hover {
    background-color: inherit;
    color: inherit;
    overflow: hidden;
  }

  .dropdownContainerVisible {
    z-index: 12;
    height: auto;
  }

  .usernameAndChevronWrapper {
    max-width: 7rem;
  }

  .profileMenuWrapper {
    display: flex;
    align-content: center;
    justify-content: flex-end;
    height: 4rem;
    cursor: pointer;
  }

  .profileDropdown {
    background-color: var(--color-white);
    top: 4rem;
    margin-top: 0;
    height: 100%;
    width: 100%;
    padding: 0 2rem 0 1.5rem;
    position: fixed;
    border-radius: 0;
    left: 0;
    z-index: 100;
    transition: all 0s;
  }
}
