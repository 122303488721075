.Illustration {
  width: 66.5%;
  margin-top: -12%;
}

.SitemapSection {
  display: grid;
  grid-template-columns: 15rem 1fr;
  gap: 1rem;
}

.SitemapSection ul {
  display: grid;
  list-style: none;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 0;
  padding: 0;
  gap: 1rem;
}

@media screen and (max-width: 60rem) {
  .SitemapSection ul {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 50rem) {
  .SitemapSection ul {
    grid-template-columns: 1fr;
  }

  .SitemapSection {
    grid-template-columns: 1fr;
  }
}
