.Page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.SkeletonWrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.Content {
  flex-grow: 1;
}

.Subtitle {
  font-size: 0.75em;
  line-height: 1.5;
  display: block;
}

.Page--illustrated .HeaderText {
  max-width: 60%;
}

.HeaderContent {
  position: relative;
  display: flex;
  z-index: 3;
}

.HeaderIllustration {
  width: 40%;
  position: absolute;
  right: 0;
  z-index: 2;
  pointer-events: none;
  margin-bottom: -40%;
}

@media screen and (max-width: 65rem) {
  .HeaderIllustration {
    display: none;
  }

  .Page--illustrated .HeaderText {
    max-width: 100%;
  }
}
