.QuickLook-header {
  display: flex;
  gap: var(--spacing-x-small);
  align-items: baseline;
  justify-items: start;
}

.QuickLook-operations {
  list-style: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--spacing-x-small);
  font-size: var(--font-size-basis);
}

.QuickLook-operations li {
  display: flex;
  gap: var(--spacing-x-small);
  align-items: center;
}

.QuickLook-method {
  padding: 0.125rem 0;
  border-radius: 0.25rem;
  color: var(--color-white);
  background-color: var(--color-sky-blue);
  white-space: nowrap;
  flex: 6rem 0 0;
  text-align: center;
  text-transform: uppercase;
  font-size: var(--font-size-small);
}

.QuickLook-method--get {
  background-color: var(--color-summer-green);
}

.QuickLook-method--post {
  background-color: var(--color-violet);
}

.QuickLook-method--delete {
  background-color: var(--color-fire-red);
}

.QuickLook-actions {
  display: flex;
  gap: var(--spacing-small);
  align-items: center;
  justify-content: flex-end;
}

@media screen and (max-width: 60rem) {
  .QuickLook-operations {
    grid-template-columns: 1fr;
  }
}
