:root {
  --color-local-interaction-green: #d4ecc5;
}

.NavigationHeader {
  position: sticky;
  top: 0;
  background-color: var(--color-white);
  width: 100%;
  z-index: 90;
  border-bottom: 1px solid var(--color-black-8);
}

.NavigationBar {
  width: 90vw;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.NavigationMenu {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.Logo {
  border-right: 1px var(--color-black-8) solid;
  padding-right: 2rem;
}

.MenuHeaderWrapper {
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: var(--color-white);
}

.MenuLinks {
  display: flex;
  justify-content: baseline;
  align-items: baseline;
  gap: 1rem;
  transition: all 0.2s;
}

.ActionMenu {
  display: flex;
  gap: 8px;
}

.ProfileDivider {
  border-right: 0;
}

@media screen and (max-width: 73rem) {
  .MenuHeader {
    text-align: center;
  }

  .NavigationMenu {
    gap: 0;
  }

  .MenuLinks {
    gap: 0;
  }

  .ActionMenu {
    gap: 0;
  }
}

@media screen and (max-width: 62rem) and (min-width: 0) {
  .NavigationBar {
    width: 95vw;
  }

  .Logo {
    border-right: 0;
    padding-right: 0;
  }

  .ProfileDivider {
    border-right: solid 1px var(--color-black-8);
    margin: 0.5rem 0;
    margin-left: 1rem;
  }
}
