:root {
  --shadow-default: none;
}

.filterTitle {
  color: var(--color-sea-green);
  cursor: pointer;
}

.activeFilterBox {
  box-shadow: 0 0.5rem 1rem 0 var(--color-black-20);
  border-radius: 0.25rem;
  height: fit-content;
  position: sticky;
  top: 5rem;
}

.activeTags {
  height: fit-content;
}

@media screen and (max-width: 60rem) {
  .activeFilterBox {
    width: 100%;
    box-shadow: 0 0.5rem 1rem 0 var(--color-black-20);
    position: relative;
    top: 0;
  }
}
