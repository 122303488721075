.schema-item-list {
  margin: 0;
  padding: 0;
  border: 1px solid var(--color-black-8);
  list-style: none;

  li:nth-of-type(odd) {
    background-color: var(--color-white);
  }

  li:nth-of-type(even) {
    background-color: var(--color-black-3);
  }
}
