.ModalPage {
  padding: 3rem 0;
  padding-top: 0;
  min-height: 100vh;
  /* For some reason Eufemia has turned this off */
  /* TODO: Remove when fixed */
  user-select: text;
}

.ModalActionHeader {
  background-color: var(--color-white);
  padding: 3rem 3rem 1rem 3rem;
}
.ModalActionHeaderContain {
  display: flex;
  justify-content: flex-end;
}

.ModalActionHeaderText {
  flex-grow: 1;
}

.Description {
  max-width: 40rem;
}

.Wrapper {
  flex-grow: 1;
  overflow-y: auto;
  height: 100vh;
}

@media screen and (max-width: 50rem) {
  .ModalActionHeader {
    background-color: var(--color-white);
    padding: 1rem 1rem 1rem 1rem;
  }
  .ModalActionHeaderContain {
    background-color: var(--color-white);
    flex-direction: column-reverse;
    display: flex;
  }
  .ModalActionCloseBtn {
    align-self: flex-end;
  }
}
