html {
  scroll-padding-top: 4rem;
}

.dnb-section--mint-green-25::after {
  color: var(--color-mint-green-25);
}

.dnb-alignment-helper {
  display: none;
}

#tile.dnb-card.dnb-section {
  --outline-width: none;
}

#dnb-modal-tagFilterDrawer-content {
  background-color: var(--color-black-3);
  height: 100%;
}
