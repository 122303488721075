.AppKeyTile {
  row-gap: var(--spacing-small);
  background-color: var(--color-lavender);
  border: none;
}

.Subtext {
  color: var(--color-black-55);
  font-size: var(--font-size-x-small);
}

.AppKeyTileContent {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: var(--spacing-x-small);
}
.AppKeyTileButtons {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  gap: var(--spacing-x-small);
}

@media screen and (max-width: 27rem) {
  .AppKeyTileContent {
    flex-direction: column;
    align-items: center;
  }
  .AppKeyTileContent div {
    width: 100%;
  }
  .Label {
    text-align: left;
  }
}
