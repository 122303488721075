.pageContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  max-width: 100%;
  margin: 0;
  padding: 0;
}

.Content {
  width: 80rem;
  margin-left: auto;
  margin-right: auto;
  min-height: 57.45vh;
  flex-grow: 1;
}

@media screen and (max-width: 83rem) {
  .Content {
    width: 95%;
  }
}
