.card-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.CodeBlock {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
}

.integration-service-button-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
