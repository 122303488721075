.PrivacyPolicy {
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: 1rem;
  align-items: start;
}

.ScrollMenu {
  position: sticky;
  top: 3rem;
}

@media screen and (max-width: 60rem) {
  .ScrollMenu {
    display: none;
  }

  .PrivacyPolicy {
    display: block;
  }
}
