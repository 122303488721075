.moveable-component {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}

.text-wrapper {
  margin: auto;
  max-width: 800px;
}
